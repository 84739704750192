import { createContext, useContext, useEffect, useMemo, useState } from 'react'

import { ApolloError } from '@apollo/client/errors'
import {
  useFindInvoiceLazyQuery,
  useFindPartnerByPaymentsPortalUrlLazyQuery,
  CustomerPartnerFragment,
  CustomerFindInvoiceFragment
} from 'graph/generated/payments/graphql-types'
import Cookies from 'js-cookie'
import { useRouter } from 'next/router'
import { createBrandColor } from 'utils/colors'
import { AppCookie } from 'utils/cookies'
import { ColorProps } from 'utils/types'
import { origin, paymentsDomainLocalFix } from 'utils/window'

/**
 * Provider that supplies company information when a company url is provided.
 * Supplies invoice information when id is url param
 */

interface ICompanyAndInvoiceContext {
  company: CustomerPartnerFragment
  brandColor: ColorProps
  invoice: CustomerFindInvoiceFragment | null
  error: ApolloError
  loading: boolean
  paymentAmount: number
  setPaymentAmount: (amount: number) => void
  installmentTerm: number
  setInstallmentTerm: (term: number) => void
  payInInstallments: boolean
  setPayInInstallments: (payInInstallments: boolean) => void
  payAsGuest: boolean
  setPayAsGuest: (payAsGuest: boolean) => void
  cardFee: number
  setCardFee: (fee: number) => void
}

export const CompanyAndInvoiceContext =
  createContext<ICompanyAndInvoiceContext>({
    company: null,
    brandColor: null,
    invoice: null,
    error: null,
    loading: false,
    paymentAmount: 0,
    setPaymentAmount: () => {},
    installmentTerm: 1,
    setInstallmentTerm: () => {},
    payInInstallments: false,
    setPayInInstallments: () => {},
    payAsGuest: false,
    setPayAsGuest: () => {},
    cardFee: 0,
    setCardFee: () => {}
  })

const CompanyAndInvoiceProvider = ({ children }) => {
  const {
    query: { invoice },
    pathname
  } = useRouter()

  const [installmentTerm, setInstallmentTerm] = useState<number>(1)
  const [paymentAmount, setPaymentAmount] = useState<number | null>(null)
  const [payInInstallments, setPayInInstallments] = useState<boolean>(false)
  const [payAsGuest, setPayAsGuest] = useState<boolean>(
    Cookies.get(AppCookie.LoggedAsGuest) === 'true'
  )
  const [cardFee, setCardFee] = useState<number>(0)

  const [fetchCompany, companyQuery] =
    useFindPartnerByPaymentsPortalUrlLazyQuery()
  const [fetchInvoice, invoiceQuery] = useFindInvoiceLazyQuery()

  const value = useMemo(() => {
    const company = companyQuery.data?.FindPartnerByPaymentsPortalUrl
    // set only on the first load
    if (!paymentAmount) {
      setPaymentAmount(invoiceQuery.data?.FindInvoice?.outstandingAmount ?? 0)
    }

    return {
      company,
      brandColor: createBrandColor(company?.paymentsPortalCustomization?.color),
      invoice: invoiceQuery.data?.FindInvoice,
      loading: companyQuery.loading,
      error: companyQuery.error || invoiceQuery.error,
      paymentAmount,
      setPaymentAmount,
      installmentTerm,
      setInstallmentTerm,
      payInInstallments,
      setPayInInstallments,
      payAsGuest,
      setPayAsGuest,
      cardFee,
      setCardFee
    }
  }, [
    companyQuery.data,
    invoiceQuery.data,
    companyQuery.loading,
    paymentAmount,
    installmentTerm,
    payInInstallments,
    payAsGuest,
    cardFee
  ])

  useEffect(() => {
    if (pathname.includes('installments')) {
      setPayInInstallments(true)
    }
  }, [pathname])

  useEffect(() => {
    fetchCompany({ variables: { url: paymentsDomainLocalFix } })
    if (invoice) {
      setPaymentAmount(null)
      setInstallmentTerm(1)
      fetchInvoice({ variables: { id: invoice as string } })
    }
  }, [invoice, origin])

  return (
    <CompanyAndInvoiceContext.Provider value={value}>
      {children}
    </CompanyAndInvoiceContext.Provider>
  )
}

export const useCompanyAndInvoiceContext = () =>
  useContext(CompanyAndInvoiceContext)

export default CompanyAndInvoiceProvider
