import { gql, ReactiveVar } from '@apollo/client'

import makeVarPersisted from './makeVarPersisted'

export interface IPlaid extends IConnect {
  token?: string | null
}

export interface IConnect {
  nextUrl?: string | null
}

export enum PaymentMethod {
  ach = 'ach',
  transfer = 'transfer',
  bnpl = 'bnpl',
  card = 'card'
}

export interface IPaymentMethod {
  bank?: string
  method: PaymentMethod
}

export interface ITerms {
  amount: number
  installment: number
  rate: number
  term: number
  invoiceID: string
  partnerID: string
  method: PaymentMethod
}

export const typeDefs = gql`
  type Auth {
    email: String
    redirectUrl: String
  }
  type PlaidProps {
    token: String
    nextUrl: String
  }
  extend type Query {
    auth: Auth
    accessToken: String
    plaid: PlaidProps
  }

  directive @client on FIELD
`

const plaidInitial: IPlaid = {
  token: '',
  nextUrl: ''
}

export const plaidVars: ReactiveVar<IPlaid> = makeVarPersisted<any | undefined>(
  plaidInitial,
  'alt-plaid'
)

export const quickbookVars: ReactiveVar<IConnect> = makeVarPersisted<
  any | undefined
>({ nextUrl: '' }, 'alt-quickbooks')

export const termsVars: ReactiveVar<ITerms> = makeVarPersisted<any | undefined>(
  null,
  'alt-terms'
)

export const paymentVars: ReactiveVar<IPaymentMethod> = makeVarPersisted<
  any | undefined
>(null, 'alt-payment')
